import iconVmcDouble from '../../../assets/images/icones-travaux/vmc-double-flux.svg';
import iconVmcSimple from '../../../assets/images/icones-travaux/vmc-simple-flux.svg';
import thermostatExterieur from '../../../assets/images/icones-travaux/thermostat-programmable.svg';
import thermostatProgrammable from '../../../assets/images/icones-travaux/thermostat-exterieur.svg';
import systemeSolaireCombine from '../../../assets/images/icones-travaux/systeme-solaire-combine.svg';
import renovationGlobale from '../../../assets/images/icones-travaux/renovation-globale.svg';
import radiateurBasseTemperature from '../../../assets/images/icones-travaux/radiateur-basse-temperature.svg';
import radiateurElectriqueRayonnant from '../../../assets/images/icones-travaux/radiateur-electrique-rayonnant.svg';
import radiateurElectriqueRegulationElectronique from '../../../assets/images/icones-travaux/radiateur-electrique-regulation-electronique.svg';
import pompeChaleurAirEau from '../../../assets/images/icones-travaux/pompe-chaleur-air-eau.svg';
import pompeChaleurEauEau from '../../../assets/images/icones-travaux/pompe-chaleur-eau-eau.svg';
import pompeChaleurAirAir from '../../../assets/images/icones-travaux/pompe-chaleur-air-air.svg';
import poeleBuches from '../../../assets/images/icones-travaux/poele-buches.svg';
import poeleGranules from '../../../assets/images/icones-travaux/poele-granules.svg';
import plancherChauffant from '../../../assets/images/icones-travaux/plancher-chauffant.svg';
import isolationComblesPerdus from '../../../assets/images/icones-travaux/isolation-combles-perdus.svg';
import isolationComblesAmenages from '../../../assets/images/icones-travaux/isolation-combles-amenages.svg';
import isolationMursInterieur from '../../../assets/images/icones-travaux/isolation-murs-interieur.svg';
import isolationMursExterieur from '../../../assets/images/icones-travaux/isolation-murs-exterieur.svg';
import isolationPlancherBas from '../../../assets/images/icones-travaux/isolation-plancher-bas.svg';
import isolationToituresTerrasses from '../../../assets/images/icones-travaux/isolation-toitures-terrasses.svg';
import fermetureIsolante from '../../../assets/images/icones-travaux/fermeture-isolante.svg';
import fenetrePorteFenetre from '../../../assets/images/icones-travaux/fenetre-porte-fenetre.svg';
import fenetreToiture from '../../../assets/images/icones-travaux/fenetre-toiture.svg';
import fenetreVitrageParietodynamique from '../../../assets/images/icones-travaux/fenetre-vitrage-parietodynamique.svg';
import chemineeFoyerFerme from '../../../assets/images/icones-travaux/cheminee-foyer-ferme.svg';
import chauffeEauSolaire from '../../../assets/images/icones-travaux/chauffe-eau-solaire.svg';
import chauffeEauThermodynamique from '../../../assets/images/icones-travaux/chauffe-eau-thermodynamique.svg';
import chaudiereGaz from '../../../assets/images/icones-travaux/chaudiere-gaz.svg';
import chaudiereBuches from '../../../assets/images/icones-travaux/chaudiere-buches.svg';
export var works = [
    {
        key: 'BAR-TH-125-01',
        label: 'VMC double flux',
        profits: ['B02', 'B11', 'B12'],
        icon: iconVmcDouble,
        desc: 'Ce système de ventilation de l’air intérieur permet de récupérer la chaleur de l’air sortant pour la distribuer à l’air entrant.',
    },
    {
        key: 'BAR-TH-127-01',
        label: 'VMC simple flux hygroréglable',
        profits: ['B11'],
        icon: iconVmcSimple,
        desc: 'Ce système de ventilation permet le renouvellement de l’air intérieur en fonction de l’humidité de la pièce.',
    },
    {
        key: 'BAR-TH-111-01',
        label: 'Thermostat extérieur',
        profits: ['B02', 'B10'],
        icon: thermostatExterieur,
        desc: 'Il permet de régler la température intérieure en fonction de la température extérieure. Ainsi, elle assure une température constante dans le logement.',
    },
    {
        key: 'BAR-TH-118-01',
        label: 'Thermostat programmable',
        profits: ['B01', 'B02', 'B10'],
        icon: thermostatProgrammable,
        desc: 'Ce système permet de réguler la température du chauffage en fonction des habitudes des occupants du logement.',
    },
    {
        key: 'BAR-TH-143-01',
        label: 'Système solaire combiné',
        profits: ['B01', 'B08'],
        icon: systemeSolaireCombine,
        desc: "Composé d'un panneau solaire thermique et d'un ballon de stockage, ce système permet de produire de l’eau chaude et de chauffer votre logement.",
    },
    {
        key: 'BAR-TH-164-01',
        label: 'Rénovation globale',
        profits: ['B01', 'B02', 'B03', 'B08', 'B09', 'B11', 'B13'],
        icon: renovationGlobale,
        desc: 'L’objectif est de réaliser des travaux performants (isolation, chauffage, ventilation) pour atteindre une importante quantité d’énergie économisée.',
    },
    {
        key: 'BAR-TH-110-01',
        label: 'Radiateur basse température',
        profits: ['B01', 'B02'],
        icon: radiateurBasseTemperature,
        desc: 'Ce radiateur chauffe à une température maximale de 50° C et permet le même confort qu’un autre radiateur, grâce à sa large surface de contact avec l’air. ',
    },
    {
        key: 'BAR-TH-158-01',
        label: 'Radiateur électrique rayonnant',
        profits: ['B02'],
        icon: radiateurElectriqueRayonnant,
        desc: 'Le radiateur rayonnant chauffe une pièce grâce à la convection et au rayonnement électromagnétique par infrarouge. Il diffuse rapidement une chaleur homogène.',
    },
    {
        key: 'BAR-TH-158-02',
        label: 'Radiateur électrique à régulation électronique',
        profits: ['B02'],
        icon: radiateurElectriqueRegulationElectronique,
        desc: 'Ce type de radiateur électrique est dit « intelligent ». Il a une fonction de détection, de régulation et d’information.',
    },
    {
        key: 'BAR-TH-159-01',
        label: 'Pompe à chaleur hybride',
        profits: ['B01', 'B08', 'B09', 'B13'],
        icon: pompeChaleurAirEau,
        desc: 'Ce système de chauffage permet d’assurer le chauffage de votre logement grâce à l’air extérieur.',
    },
    {
        key: 'BAR-TH-104-02',
        label: 'Pompe à chaleur eau/eau',
        profits: ['B01', 'B09', 'B13'],
        icon: pompeChaleurEauEau,
        desc: 'Ce système de chauffage permet d’assurer le chauffage de votre logement grâce aux calories présentes dans les eaux souterraines.',
    },
    {
        key: 'BAR-TH-129-01',
        label: 'Pompe à chaleur air/air',
        profits: ['B01', 'B09', 'B13'],
        icon: pompeChaleurAirAir,
        desc: 'Ce système de chauffage récupère les calories présentes dans l’air extérieur pour chauffer ou refroidir l’air intérieur de votre logement.',
    },
    {
        key: 'BAR-TH-112-02',
        label: 'Poêle à buches',
        profits: ['B08'],
        icon: poeleBuches,
        desc: 'Le poêle à buches est un système de chauffage esthétique par combustion de bûches de bois.',
    },
    {
        key: 'BAR-TH-112-03',
        label: 'Poêle à granulés',
        profits: ['B08'],
        icon: poeleGranules,
        desc: 'Le poêle à granulés est un système de chauffage esthétique par combustion de granulés de bois.',
    },
    {
        key: 'BAR-TH-116-01',
        label: 'Plancher chauffant',
        profits: ['B01', 'B02', 'B13'],
        icon: plancherChauffant,
        desc: "Le plancher chauffant diffuse une chaleur homogène et douce. Il permet de moins consommer d'énergie tout en se chauffant.",
    },
    {
        key: 'BAR-EN-101-01',
        label: 'Isolation des combles perdus',
        profits: ['B01', 'B02', 'B03', 'B04', 'B13'],
        icon: isolationComblesPerdus,
        desc: 'Un isolant est posé directement au-dessus du plafond (sol de votre grenier). Il permet d’empêcher la circulation de la chaleur ou du froid entre l’intérieur et l’extérieur de votre logement.',
    },
    {
        key: 'BAR-EN-101-02',
        label: 'Isolation des combles aménagés',
        profits: ['B01', 'B02', 'B03', 'B04', 'B13'],
        icon: isolationComblesAmenages,
        desc: 'Un isolant est posé directement sous la toiture et permet d’empêcher la circulation de la chaleur ou du froid entre l’intérieur et l’extérieur de votre logement.',
    },
    {
        key: 'BAR-EN-102-01',
        label: "Isolation des murs par l'intérieur",
        profits: ['B01', 'B05', 'B06', 'B07', 'B13'],
        icon: isolationMursInterieur,
        desc: 'Un isolant est posé sur les parois intérieures de votre logement et permet d’empêcher la circulation de la chaleur ou du froid entre l’intérieur et l’extérieur.',
    },
    {
        key: 'BAR-EN-102-02',
        label: "Isolation des murs par l'exterieur",
        profits: ['B01', 'B02', 'B04', 'B13'],
        icon: isolationMursExterieur,
        desc: 'Un isolant est posé entre les murs extérieurs de votre logement et les différentes couches de matériaux de parement (enduit, bois, pierre…).',
    },
    {
        key: 'BAR-EN-103-01',
        label: 'Isolation du plancher en surface',
        profits: ['B01', 'B02', 'B03', 'B13'],
        icon: isolationPlancherBas,
        desc: 'Un isolant thermique est placé sous le revêtement de votre plancher.',
    },
    {
        key: 'BAR-EN-103-02',
        label: 'Isolation du plancher en sous-face',
        profits: ['B01', 'B02', 'B05', 'B10', 'B13'],
        icon: isolationPlancherBas,
        desc: 'Un isolant thermique est placé sous la dalle de votre plancher (ex : au-dessus du vide sanitaire).',
    },
    {
        key: 'BAR-EN-105-01',
        label: 'Isolation des toitures terrasses',
        profits: ['B01', 'B02', 'B03', 'B04'],
        icon: isolationToituresTerrasses,
        desc: 'Un isolant est placé sur votre toiture terrasse et permet d’empêcher la circulation de la chaleur ou du froid entre l’intérieur et l’extérieur.',
    },
    {
        key: 'BAR-EN-108-01',
        label: 'Fermeture isolante',
        profits: ['B01', 'B02', 'B03', 'B06', 'B13'],
        icon: fermetureIsolante,
        desc: 'Une fermeture isolante (volet) empêche la chaleur de rentrer dans votre logement en été et en hiver le froid de rentrer.',
    },
    {
        key: 'BAR-EN-104-01',
        label: 'Fenêtre et/ou porte-fenêtre',
        profits: ['B01', 'B02', 'B03', 'B06', 'B13'],
        icon: fenetrePorteFenetre,
        desc: 'Les menuiseries double vitrage disposent de meilleures performances thermiques que les menuiseries simple vitrage.',
    },
    {
        key: 'BAR-EN-104-02',
        label: 'Fenêtre de toiture',
        profits: ['B01', 'B02', 'B03', 'B06'],
        icon: fenetreToiture,
        desc: 'Les menuiseries double vitrage disposent de meilleures performances thermiques que les menuiseries simple vitrage.',
    },
    {
        key: 'BAR-EN-110-01',
        label: 'Fenêtre ou porte-fenêtre en triple vitrage',
        profits: ['B01', 'B02', 'B03', 'B06', 'B13'],
        icon: fenetreVitrageParietodynamique,
        desc: "Ces menuiseries à 3 vitrages permettent de réduire les déperditions thermiques et d'optimiser la captation d’énergie solaire grâce à l’effet de serre.",
    },
    {
        key: 'BAR-TH-112-01',
        label: 'Cheminée à foyer fermé',
        profits: ['B08'],
        icon: chemineeFoyerFerme,
        desc: 'Les foyers fermés ont un excellent rendement et permettent de chauffer la pièce efficacement.',
    },
    {
        key: 'BAR-TH-101-01',
        label: 'Chauffe-eau solaire',
        profits: ['B01', 'B08'],
        icon: chauffeEauSolaire,
        desc: 'Des panneaux solaires placés sur le toit de votre logement permettent de capter la lumière du soleil et de réchauffer l’eau que vous pouvez utiliser dans votre logement.',
    },
    {
        key: 'BAR-TH-148-01',
        label: 'Chauffe-eau thermodynamique',
        profits: ['B01', 'B09'],
        icon: chauffeEauThermodynamique,
        desc: "Ce chauffe-eau produit de l’eau chaude en captant les calories de l’air extérieur, ce qui permet de consommer moins d'énergie qu’un chauffe-eau classique. ",
    },
    {
        key: 'BAR-TH-106-01',
        label: 'Chaudière gaz à haute performance énergétique',
        profits: ['B01', 'B13'],
        icon: chaudiereGaz,
        desc: 'Ce type de chaudière est très performant. La combustion est optimisée en récupérant une partie de l’énergie contenue dans la vapeur d’eau.',
    },
    {
        key: 'BAR-TH-113-01',
        label: 'Chaudière à bûches',
        profits: ['B08', 'B13'],
        icon: chaudiereBuches,
        desc: 'Ce type de chaudière a un très bon rendement. Le  combustible utilisé provient de ressources renouvelables ce qui est bénéfique pour l’environnement.',
    },
    {
        key: 'BAR-TH-104-01',
        label: 'Pompe à chaleur air/eau',
        profits: ['B01', 'B08', 'B09', 'B13'],
        icon: pompeChaleurAirEau,
        desc: 'Ce système de chauffage permet d’assurer le chauffage de votre logement grâce à l’air extérieur.',
    },
];
export var profits = [
    { code: 'B01', icon: 'icon-globale', label: "Economies d'énergie" },
    { code: 'B02', icon: 'icon-temp', label: 'Confort thermique' },
    { code: 'B03', icon: 'icon-noise', label: 'Confort acoustique' },
    { code: 'B04', icon: 'icon-thermal-bridge', label: 'Ponts thermiques réduits' },
    { code: 'B05', icon: 'icon-easy', label: 'Mise en œuvre facile' },
    { code: 'B06', icon: 'icon-cold-wall', label: 'Effet paroi froide diminué' },
    { code: 'B07', icon: 'icon-outside', label: 'Aspect extérieur préservé' },
    { code: 'B08', icon: 'icon-ecology', label: 'Utilisation des énergies renouvelables' },
    { code: 'B09', icon: 'icon-heat', label: 'Production de chaleur optimisée' },
    { code: 'B10', icon: 'icon-simple', label: 'Simple et efficace' },
    { code: 'B11', icon: 'icon-cloud', label: 'Meilleure qualité de l’air' },
    { code: 'B12', icon: 'icon-air-quality', label: 'Utilisation des calories de l’air extrait' },
    { code: 'B13', icon: 'icon-valorize', label: 'Valorisation du bien' },
];
